import axios from 'axios'
import { Toast, Dialog } from 'vant';

const TIMEOUT = 30000
// const BASE_URL = 'https://nativz.online/api'
// const BASE_URL = 'http://localhost:8085/api'
// const BASE_URL = 'https://nativz.net/api'
const BASE_URL = 'https://usemobile.net/api'


const service = axios.create({
    baseURL: BASE_URL,
    timeout: TIMEOUT,
})

service.interceptors.request.use(config => {
    const token = localStorage.getItem('token')
    if (token) {
        config.headers.token = token
    }
    return config
})

service.interceptors.response.use(response => {
    const data = response.data
    try {
        if (data.code === 200) {
            return data.data || data.rows
        } else if (data.code === 508) {
            return data
        } else {
            const message = data.msg
            if (message) {
                Dialog.alert({
                    title: "Tips",
                    message: message,
                    confirmButtonText: "Sure",
                })
            } else {
                Dialog.alert({
                    title: "Tips",
                    message: 'System error, please wait a moment!',
                    confirmButtonText: "Sure",
                })
            }

            return Promise.reject(data)
        }
    } catch (e) {
        Dialog.alert({
            title: "Tips",
            message: 'System error, please wait a moment!',
            confirmButtonText: "Sure",
        })
    }

}, error => {
    try {
        const message = error.response.data.msg
        if (message) {
            Toast.fail(message)
        } else {
            Dialog.alert({
                title: "Tips",
                message: 'System error, please wait a moment!',
                confirmButtonText: "Sure",
            })
        }

        return Promise.reject(error)
    } catch (e) {
        Dialog.alert({
            title: "Tips",
            message: 'System error, please wait a moment!',
            confirmButtonText: "Sure",
        })
    }

})

export default service